<template>
  <b-card
    bg-variant="light"
    class="mt-3"
  >
    <aom-skeleton-loader
      v-if="isLoadingUser"
      :loader-type="loaderTypes.SHORT"
    />
    <section
      v-if="!user && !isLoadingUser"
    >
      <div class="text-center m-4">
        <b-avatar
          size="72"
          variant="primary"
          class="mr-2"
        >
          <feather-icon
            icon="UserIcon"
            size="42"
          />
        </b-avatar>
        <h3 class="mt-1">
          No User selected or found
        </h3>
      </div>
    </section>
    <div v-if="user && !isLoadingUser">    
      <b-row>
        <b-col
          sm="8"
          class="d-flex flex-row"
        >
          <b-media>
            <template #aside>
              <b-avatar
                variant="primary"
                :text="userInitials(user)"
                :src="userProfileImage(user)"
                size="5rem"
              />
            </template>
            <h5 class="mt-1 font-weight-500">
              <span v-if="user.full_name">{{ user.full_name }}</span>
            </h5>
          </b-media> 
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          class="mt-2"
        >
          <ul class="list-style-none pl-1">
            <li
              v-for="question in suggestedUserVisbileQuestions"
              :key="question.id"
            >
              <div
                class="list-style-none"
              >
                <p>
                  <b>{{ getTranslationTextByUserLocale(question.translations, 'question_text') }}</b>
                </p>
                <p>
                  <span v-if="(question.type_id === questionTypes.TEXT && question.answers.length > 0)">{{ question.answers[0].answer_text }}</span>
                  <span v-if="(question.type_id === questionTypes.SCALE)">{{ getStatementsChoicesTranslation(question) }}</span>
                  <span v-if="(question.type_id === questionTypes.CHOICE || question.type_id === questionTypes.MATCHING)">{{ getQuestionChoicesAnswerTextTranslation(question) }}</span>
                </p>
              </div>
            </li>
          </ul>
        </b-col>
      </b-row>
    </div>   
  </b-card>
</template>
<script>
import { BCard,BRow, BCol, BAvatar, BMedia } from "bootstrap-vue";
import { mapGetters } from "vuex";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { userRoles, questionTypes, loaderTypes } from '@models';
  
export default {
  name: "UserDetail",
  components: {
    BRow, 
    BCol, 
    BAvatar,
    BMedia,
    BCard,
    AomSkeletonLoader
  },
  props: {
    user: {
      type: Object,
      default: () => {}
    },
    roleId: {
      type: Number,
      default: userRoles.MENTEE
    },
    isLoadingUser: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters("profile", ["profile", "preferredLanguageId"]),
    ...mapGetters("app", ["currentProgram"]),
    asRoleId () {
      return this.roleId;
    },
    userAsRoleIsMentor() {
      return this.asRoleId === userRoles.MENTOR;
    },
    suggestedUserVisbileQuestions () {
      if(this.user && this.user.user_forms) {
        return this.user?.user_forms[0].form.questions;
      }
      if(this.user && this.user.requestee && this.user.requestee.user_forms) {
        return this.user?.requestee.user_forms[0].form.questions;
      }
      if(this.user && this.user.requester && this.user.requester.user_forms) {
        return this.user?.requester.user_forms[0].form.questions;
      }
      if(this.user && this.user.shortlistee && this.user.shortlistee.user_forms) {
        return this.user?.shortlistee.user_forms[0].form.questions;
      }
      return [];
    }
  },
  methods: {
    userProfileImage (user) {
      return user?.profile_image?.url || '';
    },
    userInitials(user) {
      if(user && user.first_name) {
        const firstInt = user? Array.from(user?.first_name)[0]: '';
        const lastInt = user? Array.from(user?.last_name)[0]: '';
        return `${firstInt}${lastInt}`;
      }
      return '';
    },
    getTranslationTextByUserLocale(translations, propertyName) {
      return this.$store.getters['participants/getTranslationTextByUserLocale'](translations, propertyName);
    },
    getQuestionChoicesAnswerTextTranslation(question) {
      if(question.choices.length > 0 ) {
        const choice = question.choices.flatMap(c => c.translations.filter(t => t.locale_id === this.preferredLanguageId));
        if(choice.length > 0 && question.answers.length > 0) {
          const ansIds = question.answers.map(c => c.choice_id);
          if(ansIds.length > 0) {
            const matchedAns = choice.filter(c => ansIds.includes(c.question_choice_id));
            return matchedAns.map(a => a.choice_text).join(', ');
          }
        }
      }
      return;
    },
    getStatementsChoicesTranslation(question) {
      if(question.statements.length > 0 ) {
        const state = question.statements.filter(s => s.answers.length > 0);
        // find the translated choice
        if(state.length > 0) {
          const matchedChoice = question.choices.filter(c => c.id === state[0].answers[0].choice_id).shift();
          const choiceTrans = matchedChoice.translations.filter(t => t.locale_id === this.preferredLanguageId).shift();
          const stateTrans = state[0].translations.filter(t => t.locale_id === this.preferredLanguageId).shift();
          if(choiceTrans && stateTrans) {
            return `${stateTrans.question_text} - ${choiceTrans.choice_text}`;
          }
        }
        return;
      }
      return;
    }
  },
  setup() {
    return {
      questionTypes, 
      userRoles,
      loaderTypes
    };
  }
};
</script>
  
  <style lang="scss" scoped>
  .suggested-user-list {
    cursor: pointer;
  }
  .selected {
    background: #f8f8f8;
    border-left: 5px solid var(--aom-color-secondary);
  }
  .list-style-none {
    list-style: none;
  }
  </style>