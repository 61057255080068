<template>
  <b-card>
    <AomSkeletonLoader v-if="isLoading" />
    <b-row v-else>
      <b-col sm="10">
        <h5
          v-if="!hasSelectedPreferencedMentors"
          class="mt-1"
        >
          Select at least {{ selectionLimit }} mentors you would prefer to be matched with from the list below.
        </h5>
        <h6 v-if="!hideOrderingOfPreferences && !hasSelectedPreferencedMentors">
          <i>Drag and Drop preferred mentors in the order you prefer with the highest preference one’s at the top</i>
        </h6>
        <h5
          v-if="hasSelectedPreferencedMentors"
          class="mt-1"
        >
          You have already selected your Preferred Mentors.
        </h5>
      </b-col>
      <b-col sm="6">
        <draggable
          v-if="!isLoading && !hideOrderingOfPreferences"
          v-model="suggestionList"
          class="list-group list-group-flush  mt-3"
          :class="{ 'cursor-move': !hasSelectedPreferencedMentors }"
          tag="ul"
        >
          <b-list-group-item
            v-for="user in suggestionList"
            :key="user.id"
            tag="li"
            :class="{'selected': selected === user.id }"
            @click="fetchSuggestedUser(user.id)"
          >
            <b-container>
              <b-row class="d-flex">
                <b-col
                  sm="2"
                  class="sort-wrapper d-flex justify-content-start align-items-center"
                >
                  <feather-icon
                    v-if="!hasSelectedPreferencedMentors"
                    icon="MoveIcon"
                    size="20"
                    class="mr-1"
                  />
                  <b-form-checkbox
                    v-model="preferredMentors"
                    name="preferred-mentor"
                    :value="user.id"
                    :disabled="hasSelectedPreferencedMentors"
                    @change="onSelectedPreferencedMentors(user.id)"
                  />
                </b-col>
                <b-col class="d-flex justify-content-start">
                  <b-media>
                    <template #aside>
                      <b-avatar
                        variant="primary"
                        :text="userInitials(user)"
                        :src="userProfileImage(user)"
                        size="3rem"
                      />
                    </template>
                    <h5 class="mt-1 font-weight-500">
                      <span v-if="user.full_name">{{ user.full_name }}</span>
                      <span v-else>Participant Visibility setting is off.</span>
                    </h5>
                  </b-media>   
                </b-col>
                <b-col>
                  <div
                    class="d-flex justify-content-end"
                  >
                    <div>
                      <b-button
                        variant="outline-none"
                        @click="viewUserApplication(user)"
                      >
                        <feather-icon
                          icon="EyeIcon"
                          size="20"
                        />
                      </b-button>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </b-list-group-item>
        </draggable>
        <section
          v-if="!isLoading && hideOrderingOfPreferences"
          class="mt-3"
        >
          <b-list-group-item
            v-for="user in suggestionList"
            :key="user.id"
            tag="li"
            class="list-group list-group-flush cursor-pointer"
            :class="{'selected': selected === user.id }"
            @click="fetchSuggestedUser(user.id)"
          >
            <b-container>
              <b-row>
                <b-col
                  sm="1"
                  class="d-flex align-items-center justify-content-start"
                >
                  <b-form-checkbox
                    v-model="preferredMentors"
                    name="preferred-mentor"
                    :value="user.id"
                    :disabled="hasSelectedPreferencedMentors"
                    @change="onSelectedPreferencedMentors(user.id)"
                  />
                </b-col>
                <b-col
                  sm="8"
                  class="d-flex justify-content-start"
                >
                  <b-media>
                    <template #aside>
                      <b-avatar
                        variant="primary"
                        :text="userInitials(user)"
                        :src="userProfileImage(user)"
                        size="3rem"
                      />
                    </template>
                    <h5 class="mt-1 font-weight-500">
                      <span v-if="user.full_name">{{ user.full_name }}</span>
                      <span v-else>Participant Visibility setting is off.</span>
                    </h5>
                  </b-media>     
                </b-col>
                <b-col>
                  <div
                    class="d-flex justify-content-end"
                  >
                    <div>
                      <b-button
                        variant="outline-none"
                        @click="viewUserApplication(user)"
                      >
                        <feather-icon
                          icon="EyeIcon"
                          size="20"
                        />
                      </b-button>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </b-list-group-item>
        </section>
      </b-col>
      <b-col sm="6">
        <user-detail
          :user="selectedUser"
          :is-loading-user="isLoadingUser"
        />
      </b-col>
    </b-row>
    <b-row v-if="!hasSelectedPreferencedMentors">
      <b-col
        sm="12"
        class="d-flex justify-content-end mt-3"
      >
        <b-button
          size="md"
          variant="primary"
          type="submit"
          :disabled="!hasSelectMinimumMentors"
          @click="submitPreferences"
        >
          <b-spinner
            v-if="isUpdating" 
            small
          />
          <span
            v-if="isUpdating"
          > Updating...</span>
          <span v-else>
            <feather-icon
              class="mr-1"
              icon="SendIcon"
              size="16"
            />Save and Submit Preferences</span>
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
const MAXIMUM_PREFERENCED_MENTORS = 5;
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BMedia,
  BListGroupItem,
  BContainer, 
  BFormCheckbox, 
  BSpinner
} from "bootstrap-vue";
import { makeErrorToast, makeSuccessToast } from "@/libs/utils";
import { userRoles } from '@models';
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { mapGetters } from "vuex";
import { matchPreferenceService, modService } from '@services';
import draggable from 'vuedraggable';
import UserDetail from './UserDetail.vue';

export default {
  name: 'ParticipantPartnerPreferencing',
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    AomSkeletonLoader,
    BAvatar,
    draggable,
    BListGroupItem,
    BContainer, 
    BMedia,
    UserDetail,
    BFormCheckbox, 
    BSpinner
  },
  data()  {
    return {
      isLoading: false,
      suggestionList: [],
      selectedUser: undefined,
      preferredMentors: [],
      selected: undefined,
      isUpdating: false,
      isLoadingUser: false,
      preferredList: []
    };
  },
  computed: {
    ...mapGetters("participants", ['isApplicationAnswersLoading']),
    ...mapGetters("programs", ["defaultProgramId", "defaultProgram"]),
    selectionLimit () {
      const menteeRole = this.defaultProgram.program_roles.filter(r => r.role_id === userRoles.MENTEE).shift();
      return menteeRole?.selection_limit;
    },
    hideOrderingOfPreferences() {
      return this.defaultProgram.hide_preference_order || this.hasSelectedPreferencedMentors;
    },
    hasSelectMinimumMentors() {
      return Number(this.preferredMentors.length) >= Number(this.selectionLimit) && Number(this.preferredMentors.length) <= MAXIMUM_PREFERENCED_MENTORS; 
    },
    hasSelectedPreferencedMentors() {
      return false;
      // const selectedMentors = this.suggestionList.filter(s => s.match_preferences_as_preferenced_user.length > 0);
      // return Number(selectedMentors.length) === Number(this.selectionLimit);
    }
  },
  watch: {
    suggestionList: {
      handler(n) {
        if(n) {
          const preferredMentors = this.suggestionList.filter(s => s.match_preferences_as_preferenced_user[0]?.preferenced_user_id);
          if(preferredMentors.length > 0) {
            this.preferredMentors = preferredMentors.map(m => m.match_preferences_as_preferenced_user[0].preferenced_user_id);
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.fetchSuggestionList();
  },
  methods: {
    onSelectedPreferencedMentors(userId) {
      if (this.preferredMentors.length > MAXIMUM_PREFERENCED_MENTORS) {
        this.$toast(makeErrorToast('You can only select a maximum of 5 mentors'));
        this.preferredMentors = this.preferredMentors.filter(id => id != userId);
        return false;
      }
      
    },
    async fetchSuggestionList() {
      try {
        this.isLoading = true;
        const programId = this.defaultProgramId;
        const response = await modService.getSuggestionList(programId, {});
        const { data } = response;
        this.suggestionList = data.items;
      } catch(e) {
        const  { status } = e.response; 
        if(status === 409) {
          this.$toast(makeErrorToast('Eligibility requirements not met. You have not completed your Application'));
        }
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchSuggestedUser(id) {
      try {
        this.isLoadingUser = true;
        const programId = this.defaultProgramId;
        const response = await modService.getSuggestionDetail(programId, id);
        const { data } = response;
        this.selected = data.id;
        this.selectedUser = data;
      } catch(e) {
        console.log(e);
        this.$toast(makeErrorToast(e));
      } finally {
        this.isLoadingUser = false;
      }
    },
    async submitPreferences() {
      try {
        this.isUpdating = true;
        const programId = this.defaultProgramId;
        const preferenceOrder = this.suggestionList.map((s,i) => ({id: s.id, preference_order: i }));
        const data = { 
          match_preferences: this.preferredMentors.map(s => ({preferenced_user_id: s, preference_order:preferenceOrder.find(p => p.id === s).preference_order }))
        };
        await matchPreferenceService.postSaveMatchPreferences(programId, data);
        this.$toast(makeSuccessToast("Preferences saved."));
        this.fetchSuggestionList();
      } catch (e) {
        console.log(e); 
        this.$toast(makeErrorToast(e));
      } finally {
        this.isUpdating = false;
      }
    },
    viewUserApplication(user) {
      this.fetchSuggestedUser(user.id);
    },
    userInitials(user) {
      if(user?.first_name) {
        const firstInt = Array.from(user.first_name)[0];
        const lastInt = Array.from(user.last_name)[0];
        return `${firstInt}${lastInt}`;
      }
    },
    userProfileImage (user) {
      return user?.profile_image?.url || '';
    },
  }
};
</script>
<style lang="scss" scoped>
    .suggested-user-list {
        cursor: pointer;
    }
    .selected {
        background: #f8f8f8;
        border-left: 5px solid var(--aom-color-secondary);
    }
    .list-style-none {
        list-style: none;
    }
</style>